//
// variables.scss
//

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

// Color
$white: #ffffff;

$primary: #a6c693; //6666FF //3f5fb8; //DDB892
$blue: #6666ff; //3f5fb8; //DDB892
$secondary: #5a6d90;
$success: #52cc99;
$warning: #ffbf66;
$info: #66ccff;
$danger: #ee2f20;
$dark: #24272c;
$black: #161c2d;
$muted: #8492a6;
$light: #f8f9fc;

// Gray
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;

//Body Background
$shadow: 0 0 3px rgba($dark, 0.15);
$shadow-md: 0 5px 13px rgba($dark, 0.2);
$shadow-lg: 0 25px 25px rgba($dark, 0.05);
$footer: lighten($black, 6%);

// Base font
$font-size-base: 15px;
$font-size-menu: 13px;

$font-family-base: "Inter", sans-serif;
$font-family-secondary: "Inter", sans-serif;

//Color Picker/Switcher
$blue: #2a52c3;
$purple: #7b2cbf;
$yellow: #f89d36;
$red: #d93030;
$green: #a6c693;
$orange: #ff5714;
$rose: #fd9a89;

// Overlay
$overlay: rgba($black, 0.75);
$bg-overlay-white: rgba($white, 0.5);
$bg-gradient-primary: linear-gradient(
  to left,
  darken($purple, 10%) 0%,
  darken($primary, 10%) 100%
);
$linear-gradient: linear-gradient(
  to bottom,
  rgba($black, 0) 0%,
  rgba($black, 0) 50%,
  rgba($black, 0.3) 80%,
  rgba($black, 1) 100%
);
$linear-gradient-2: linear-gradient(
  to bottom,
  rgba($black, 0) 0%,
  rgba($black, 0.3) 50%,
  rgba($black, 0.7) 75%,
  rgba($black, 1) 100%
);
$linear-gradient-3: linear-gradient(
  to right,
  rgba($black, 1) 0%,
  rgba($black, 0.75) 25%,
  rgba($black, 0.5) 50%,
  rgba($black, 0.25) 75%,
  rgba($black, 0) 100%
);
$gradient-overlay: linear-gradient(
  to bottom,
  rgba($black, 0) 0%,
  rgba($black, 0.5) 25%,
  rgba($black, 0.75) 50%,
  rgba($black, 1) 100%
);
$gradient-overlay-2: linear-gradient(
  to bottom,
  rgba($black, 0.5) 0%,
  rgba($black, 0.6) 25%,
  rgba($black, 0.7) 50%,
  rgba($black, 0.8) 100%
);
$primary-gradient-overlay: linear-gradient(
  to bottom,
  rgba($primary, 0) 0%,
  rgba($primary, 0.25) 25%,
  rgba($primary, 0.5) 50%,
  rgba($primary, 0.75) 75%,
  rgba($primary, 1) 100%
);
$gradient-white-overlay: linear-gradient(
  to bottom,
  rgba($white, 0) 0%,
  rgba($white, 0) 25%,
  rgba($white, 0) 50%,
  rgba($white, 0.5) 100%
);
$card-overlay: linear-gradient(
  to bottom,
  transparent 0%,
  transparent 25%,
  transparent 35%,
  rgba($black, 0.9) 100%
);

$colors: (
  "primary": $primary,
  "blue": $blue,
  "secondary": $secondary,
  "success": $success,
  "warning": $warning,
  "info": $info,
  "danger": $danger,
  "dark": $dark,
  "muted": $muted,
  "light": $light,
  "footer": $footer,
);

$heading-font-sizes: (
  "h1, .fs-1": 42px,
  "h2, .fs-2": 36px,
  "h3, .fs-3": 30px,
  "h4, .fs-4": 24px,
  "h5, .fs-5": 18px,
  "h6, .fs-6": 15px,
);

$display-font-sizes: (
  "display-1": 80px,
  "display-2": 72px,
  "display-3": 64px,
  "display-4": 56px,
  "display-5": 48px,
  "display-6": 40px,
);
