.profile-qr {
  margin: 0 auto;
  background-color: #f8f9fa;
  color: #3e3e3e;
  min-width: 100vw;
  min-height: 100vh;
}

.profile-qr-container {
  width: 100%;
  justify-content: center;
  display: flex;
}

.profile-container {
  max-width: 650px;
}

@media only screen and (max-width: 650px) {
  .profileContainer {
    max-width: 100%;
    min-width: 100%;
  }
}

@media only screen and (min-width: 651px) {
  .profileContainer {
    max-width: 650px;
    min-width: 650px;
  }
}

@media only screen and (min-width: 651px) {
  .fix-row-container {
    max-width: 650px !important;
    min-width: 650px !important;
  }
}

.header-qr {
  min-height: 200px;
  height: 200px;
  padding: 10px 0 0 0;
  font-family: "roboto";
  margin: 0 auto;
}

.container-image {
  display: flex;
  justify-content: center;
  height: 100%;
}

.profile-image {
  height: 150px !important;
  width: 150px !important;
  float: left;
  width: calc(33.333% - 1rem);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.container-text {
  text-align: center;
  height: 100%;
}

.title-qr {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
}

.region {
  font-size: 16px;
  font-weight: 100;
  text-align: center;
  margin-bottom: 0;
}

.coordinates {
  font-size: 12px;
  font-weight: 100;
  text-align: center;
  margin-bottom: 0;
  /*color: #2ac8e9;*/
}

.img-alsa {
  height: auto !important;
  width: 67px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageResize-qr {
  width: 100%;
  aspect-ratio: 1 / 1;
}

/* Custom class for the tab list */
.custom-tab-list {
  display: flex;
  justify-content: space-around;
}

/* Custom class for inactive icons */
.inactive-icon {
  color: #969696;
}

.image-list-item {
  max-width: 214px;
}

.image-title-bar {
  position: absolute;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  -moz-box-align: center;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  bottom: 0px;
  -moz-box-flex: 1;
  flex-grow: 1;
  padding: 12px 0px 0px 16px;
  color: rgb(255, 255, 255);
  overflow: hidden;
  min-height: 50px;
}

.image-title-bar-text {
  font-size: 1rem;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 80%;
}

.image_outer_container {
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 50%;
  position: relative;
}

.image_inner_container {
  border-radius: 50%;
  padding: 3px;
  background: #833ab4;
  background: -webkit-linear-gradient(to bottom, #a6c693, #8cb16c, #7c9c61);
  background: linear-gradient(to bottom, #a6c693, #8cb16c, #7c9c61);
}
.image_inner_container img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 5px solid white;
  object-fit: cover;
}

.image_outer_container .green_icon {
  background-color: #a6c693;
  position: absolute;
  right: 30px;
  bottom: 10px;
  height: 30px;
  width: 30px;
  border: 2px solid white;
  border-radius: 50%;
}

.profile-single-container {
  height: 70px;
  min-height: 70px;
  display: flex;
  justify-content: flex-start;
}

.profile-image-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 5px; /* Adjust the size of the border */
  background: linear-gradient(
    45deg,
    #a6c693,
    #8cb16c,
    #7c9c61
  ); /* Replace with your colors */
  margin-top: 5px;
  margin-left: 20px;
  float: left;
}

.profile-single-image {
  border-radius: 50%;
  height: 50px; /* Adjust as needed */
  width: 50px; /* Adjust as needed */
}

.container-single-image {
  display: flex;
  justify-content: flex-start;
}

.title-single {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0;
  margin-left: 25px;
  margin-top: 20px;
}

.description {
  font-size: 13px !important;
  padding: 10px 10px 10px 15px;
  white-space: pre-wrap;
}

.description-title {
  font-weight: bold;
  font-size: 14px !important;
}

.description svg {
  font-size: inherit;
}

.description a {
  text-decoration: none;
  color: inherit;
}

.nav-tabs .nav-link.active {
  position: relative;
}

.nav-tabs .nav-link.active::after {
  content: "";
  position: absolute;
  bottom: -1px; /* Adjust if necessary */
  left: 0;
  width: 100%;
  height: 3px; /* Adjust the thickness of the border */
  background: linear-gradient(
    to right,
    #a6c693,
    #8cb16c,
    #7c9c61
  ); /* Define your gradient */
}

.nav-tabs {
  position: relative;
}

.nav-tabs::after {
  content: "";
  position: absolute;
  bottom: -1px; /* Adjust if necessary */
  left: 0;
  width: 100%;
  height: 3px; /* Adjust the thickness of the border */
  background: linear-gradient(
    to right,
    #a6c693,
    #8cb16c,
    #7c9c61
  ); /* Define your gradient */
}

.footer-hr {
  position: relative;
}

.footer-hr::after {
  content: "";
  position: absolute;
  top: -1px; /* Adjust if necessary */
  left: 0;
  width: 100%;
  height: 3px; /* Adjust the thickness of the border */
  background: linear-gradient(
    to right,
    #a6c693,
    #8cb16c,
    #7c9c61
  ); /* Define your gradient */
}

.top-line::before,
.top-line::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px; /* Adjust the thickness of the border */
  background: linear-gradient(
    to right,
    #8cb369,
    #8cb16c,
    #7c9c61
  ); /* Gradient colors */
  z-index: 1;
}

.top-line::before {
  top: -1px;
}

.custom-tab-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.custom-tab-list .nav-item {
  flex-grow: 1; /* Makes each tab take equal space */
  text-align: center; /* Center the icon and text */
}

.custom-tab-list .nav-link {
  width: 100%;
  padding: 10px;
}

@media (max-width: 768px) {
  .custom-tab-list {
    flex-wrap: nowrap; /* Keeps all tabs in a single row on mobile */
  }
}

.imageResize {
  width: 100%;
  aspect-ratio: 1 / 1;
}

.header {
  min-height: 200px;
  height: 200px;
  padding: 25px 0 0 0;
  font-family: "roboto";
  margin: 0 auto;
}

.container-image {
  display: flex;
  justify-content: center;
  height: 100%;
}
.active-tab {
  color: #a6c693 !important;
  font-size: 14px !important;
}
.inactive-icon {
  font-size: 14px !important;
}
.profile-image {
  height: 150px !important;
  width: 150px !important;
  float: left;
  width: calc(33.333% - 1rem);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.container-text {
  text-align: center;
  height: 100%;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
}

.region {
  font-size: 16px;
  font-weight: 100;
  text-align: center;
  margin-bottom: 0;
}

.coordinates {
  font-size: 12px;
  font-weight: 100;
  text-align: center;
  margin-bottom: 0;
  /*color: #2ac8e9;*/
}

.img-alsa {
  height: auto !important;
  width: 67px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-center {
  display: flex !important;
  justify-content: center !important;
  align-content: center;
  max-width: 650px;
}

.footer-position {
  border-top-color: lightgray;
  border-top: 1px solid lightgray;
}

.footer-links {
  color: inherit;
}

.image-gallery-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.footer {
  background-color: #f8f9fa; /* Light grey background */
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-between; /* Ensure even spacing */
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto; /* Center the footer content horizontally */
}

.footer-link {
  flex: 1; /* Ensure equal spacing for each link */
  text-align: center;
  color: #a6c693; /* Bootstrap primary color */
  text-decoration: none;
  display: flex;
  flex-direction: column; /* Stack icon on top of text */
  align-items: center; /* Center icon and text horizontally */
  transition: color 0.3s ease-in-out;
}

.footer-link i {
  font-size: 24px; /* Increase icon size */
  margin-bottom: 5px; /* Add some space between icon and text */
}

.footer-link span {
  font-size: 14px; /* Text below the icon */
}

.footer-link:hover {
  color: #a6c693;
}

@media (max-width: 576px) {
  .footer-content {
    justify-content: space-evenly; /* Adjust spacing on small screens */
  }

  .footer-link {
    font-size: 12px;
  }
}

/* Container borders for top and bottom border gradient */
.container-borders {
  position: relative;
  padding: 0;
}

.container-borders::before,
.container-borders::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px; /* Adjust the thickness of the border */
  background: linear-gradient(
    to right,
    #a6c693,
    #8cb16c,
    #7c9c61
  ); /* Gradient colors */
  z-index: 1;
}

.container-borders::before {
  top: 0;
}

.container-borders::after {
  bottom: 0;
}

/* Wrapper for the ReactPlayer */
.player-wrapper {
  position: relative;
  width: 650px;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  max-height: 650px; /* Max height for larger screens */
}

/* ReactPlayer adjustment to fill container */
.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* On smaller screens, ensure the video takes full width and a proportional height */
@media (max-width: 650px) {
  .player-wrapper {
    max-height: none;
    height: 450px;
    padding-bottom: 0;
    width: 100vw;
  }
}
