//
// footer.scss
//

/*********************************/
/*         Footer                */
/*===============================*/

footer,
.footer {
    position: relative;
    color: $gray-500;

    .footer-py-60 {
        padding: 60px 0;        
    }

    .footer-py-30 {
        padding: 30px 0;        
    }
    
    .logo-footer {
        font-size: 22px;
        &:focus {
            outline: none;
        }
    }
    .footer-head {
        letter-spacing: 1px;         
        font-weight: 500;
        color: $light;
    }
    .foot-subscribe {
        .form-control {
            background-color: lighten($black, 10%);
            border: 1px solid lighten($black, 10%);
            color: $light;
            &:focus {
                box-shadow: none;
            }
        }
        &.foot-white {
            .form-control {
                color: $gray-500;
            }
        }
        input{
            &::placeholder{
                color: $gray-500;
            }
        }
    } 
    .text-foot {
        color: $gray-500;
    }
    .footer-list {
        margin-bottom: 0;
        li {
            margin-bottom: 10px;
            a{
                transition: all 0.5s ease;
                &:hover {
                    color: lighten($gray-500, 20%);
                }
            }   
            &:last-child{
                margin-bottom: 0;
            }         
        }
    }
    .footer-border,
    .footer-bar {
        border-top: 1px solid lighten($footer, 5%);
    }
    .border {
        border-color: lighten($footer, 5%) !important;
    }

    //Footer Light
    &.footer-light {
        background: $light;
        color: $muted;
        .footer-head {
            color: $dark;
        }

        .foot-subscribe {
            .form-control {
                background-color: $light;
                border: 1px solid $gray-200;
                color: $light;
            }
            &.foot-white {
                .form-control {
                    color: $gray-500;
                }
            }
            input{
                &::placeholder{
                    color: $gray-500;
                }
            }
        }

        .border {
            border-color: $gray-200 !important;
        }
        .text-foot {
            color: $muted;
        }
        .footer-list {
            li {
                a{
                    &:hover {
                        color: $gray-700;
                    }
                }   
            }
        }
    }
}