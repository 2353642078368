/* Template Name: Motos - Multipurpose Landing Page Template
   Author: Shreethemes
   Email: support@shreethemes.in
   Website: https://shreethemes.in
   Version: 1.1.0
   Created: Feb 2022
   File Description: Main Css file of the template
*/

/*********************************/
/*         INDEX                 */
/*================================
 *     01.  Variables            *
 *     02.  General              *
 *     03.  Helper               *
 *     04.  Bootstrap Custom     *
 *     05.  Components           *
 *     06.  Menu                 *
 *     07.  Home / Landing       *
 *     08.  About Us             *
 *     09.  Blog                 *
 *     11.  Countdown            *
 *     12.  Features             *
 *     13.  Testimonial          *
 *     14.  Price                *
 *     15.  Team                 *
 *     16.  Contact              *
 *     17.  Footer               *
 ================================*/

@import "variables";
@import "general";
@import "helper";
@import "bootstrap-custom";
@import "components";
@import "menu";
@import "home";
@import "blog";
@import "features";
@import "team";
@import "testi";
@import "work";
@import "contact";
@import "footer";

// .fixed-top {
//    z-index: 999;
// }

.nav-item,
.back-to-top {
  cursor: pointer;
}

.swiper-slider-hero .swiper-container .swiper-button-prev,
.swiper-slider-hero .swiper-container .swiper-button-next {
  background: transparent;
  width: 40px;
  height: 40px;
  line-height: 35px;
  margin-top: -30px;
  border: 1px solid #ffffff;
  border-radius: 30px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 12px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  font-size: 16px;
  color: #ffffff;
}

.btn-close {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e")
    center/1em auto no-repeat !important;
}

.main-color {
  color: #a6c693 !important;
}
.main-color-strong {
  color: #a6c693 !important;
  font-weight: bold !important;
}

/* Pagination container */
.react-paginate {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
  margin-top: 20px;
}
/* Individual page item */
.react-paginate li {
  margin: 0 5px;
}
/* Page number links */
.react-paginate li a {
  border-radius: 5px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  color: #6f6c7f;
  cursor: pointer;
}
/* Hover styles */
.react-paginate li a:hover {
  background-color: #f0f0f0;
}
/* Active page number */
.react-paginate li.selected a {
  background-color: #a6c693;
  color: white;
  border-color: #a6c693;
}
/* Disabled buttons (previous, next) */
.react-paginate li.disabled a {
  color: #888;
  cursor: default;
}
/* Previous and next page buttons */
.react-paginate li.previous a,
.react-paginate li.next a {
  font-weight: bold;
}

@media (min-width: 992px) {
  .post-content {
    height: 450px !important;
    overflow: hidden !important;
  }
}

.app-scrin-inner {
  position: relative;
  overflow: hidden;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0px 20px;
  margin-top: -72px;
}

.app-carousel-inner {
  position: relative;
  width: 450px;
  height: 770px;
  margin: 0 auto;
  padding: 108px 70px;
  background: url(../images/mockup.png);
  margin-top: 12px;
}
.app-carousel .single-app-image img {
  display: block;
  width: 90%;
  height: 95%;
  margin: 40px auto;
  top: 20px;
}
#appshot-paginations .swiper-pagination-bullet,
.app-carousel button.owl-dot {
  height: 7px;
  width: 7px;
  background: #ded7f4 !important;
  margin-right: 5px;
  border-radius: 50%;
  -webkit-transition: 0.3s all linear;
  transition: 0.3s all linear;
  line-height: 10px;
  vertical-align: middle;
  opacity: 1;
}
#appshot-paginations .swiper-pagination-bullet-active,
.app-carousel button.owl-dot.active {
  background: #a6c693 !important;
  height: 10px;
  width: 10px;
}
#appshot-paginations,
.app-carousel .owl-dots {
  text-align: center;
  z-index: 11;
  width: 100%;
}

@media (max-width: 1440px) {
  #appshot-paginations,
  .app-carousel .owl-dots {
    margin-top: 40px;
  }
}

.app-shot-one .container-fluid {
  max-width: 1595px;
  width: 100%;
  position: relative;
}

.app-shot-one .app-shot-one__carousel {
  padding-top: 100.5px;
  padding-bottom: 100.5px;
  margin-top: -20px;
  position: relative;
}

@media (max-width: 1440px) {
  .app-shot-one .app-shot-one__carousel {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.app-shot-one .app-shot-one__carousel:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-image: url(../images/mockup.png);
  background-position: center center;
  background-repeat: no-repeat;
  width: 450px;
  height: 770px;
  background-size: 100% auto;
}

@media (max-width: 1440px) {
  .app-shot-one .app-shot-one__carousel:before {
    display: none;
    -webkit-transform: translate(-50%, -49%) scaleY(0.95);
    transform: translate(-50%, -49%) scaleY(0.95);
  }
}

@media (max-width: 1280px) {
  .app-shot-one .app-shot-one__carousel:before {
    -webkit-transform: translate(-50%, -49%) scale(0.8);
    transform: translate(-50%, -49%) scale(0.8);
  }
}

html,
body {
  overflow-x: hidden !important;
}

.navbar .navbar-toggler {
  margin-right: 20px !important;
}
